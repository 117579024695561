import React from 'react';
import ModalBase from './ModalBase';
import { useTranslation } from 'react-i18next';
function ScannerInstructionsModal(props) {
    const { t } = useTranslation();

    return (
        <ModalBase>
            <div id="scannerInstructionModal">
                <div className="header">
                    <h1>{t("scanner-info-modal.header")}</h1>
                    <div className="flex scannerInstruction-container">
                        <div className="flex-col">
                            <ul className="scannerInstruction-list">
                                <li>{t("scanner-info-modal.line1")}</li>
                                <li>{t("scanner-info-modal.line2")}</li>
                                <li>{t("scanner-info-modal.line3")}</li>
                            </ul>
                            <i>{t("scanner-info-modal.footer")}</i>
                        </div>
                        <div className="flex-col">
                            <img className="scannerInstruction-image" src="/IM30QRCode.png" alt="QRCode logo"></img>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBase>
    )
}

export default ScannerInstructionsModal
