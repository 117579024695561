import React from 'react'
import ModalBase from './ModalBase'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",

    '& .title': {
      fontWeight: 700
    },
    '& .MuiButtonBase-root': {
      width: "150px",
      marginLeft: "10px"
    }

  },
}));


function CancelConfirmModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ModalBase>
      <div id="cancelConfirmModal" className={classes.root}>
        <h2 className="title">{t('modal.header') }</h2>
        <h3 className="gray subtitle">{props.message}</h3>
        <div className="actions mt1 flex-reverse">
                  <Button onClick={() => props.confirm()} variant="contained" className="green-btn" >{t('yes-upper')}</Button>
                  <Button onClick={() => props.cancel()} variant="contained" >{t('no-upper')}</Button>
        </div>
      </div>
    </ModalBase>
  )
}

export default CancelConfirmModal
