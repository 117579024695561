import React, { useEffect, useState } from 'react'
import BackArrow from '../images/back-arrow.png'
import awsconfig from '../awsSettings';
import { makeStyles } from '@material-ui/core/styles';
import { useConfig } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        flex: 1,

        '& .header, .footer': {
            display: "flex",
            fontSize: "34px",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: 700
        },
        '& .header': {
            margin: "20px 0 10px 10px",
        },
        '& #ProductsOfCategory': {
            flex: 1,
            maxHeight: "1630px",
            overflowY: "auto",
        },
        '& .product-flex-container': {
            alignContent: "flex-start"
        },
        '& .footer': {
            margin: "30px 0 0 10px",
        },

    },
}));

function ProductCategory(props) {
    const classes = useStyles();
    const { CustomerConfig } = useConfig();
    const { t } = useTranslation();

    const [ProductsOfCategory, setProductsOfCategory] = useState([])

    useEffect(() => {
        if (props.catId) {
            console.log(props.catId);

            const productsOfCategory = props.products.filter(p => p.ProductCategoryId === props.catId);

            console.log(productsOfCategory)
            setProductsOfCategory(productsOfCategory);
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>
            {CustomerConfig.Customer ?
                <div className="header" onClick={() => { props.backToMain(); props.resetTimer(); }}>
                    <img className="mr1" src={BackArrow} width="50" alt="back" /> {props.productCategories.find(pc => pc.Id === props.catId).Title_translated}
                </div>
                : null}
            {
                ProductsOfCategory.length ?
                    <div id="ProductsOfCategory">
                        <div className="product-flex-container">
                            {
                                ProductsOfCategory.map(p => {
                                    return (
                                        <div key={p.Id} className="product product-flex-item flex-col">
                                            {CustomerConfig.Customer ?
                                                <div className="image-container" onClick={() => props.checkCombinationDealsAvailable(p.Id)}>
                                                    {
                                                        props.hasSinglePromotion(p.Id).Bool ?
                                                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${props.hasSinglePromotion(p.Id).OfferId}_${props.hasSinglePromotion(p.Id).OfferAvatar}`} alt={props.hasSinglePromotion(p.Id).OfferId} />
                                                            :
                                                            <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/products/pid${p.Id}_${ProductsOfCategory.find(prod => prod.Id === p.Id).Avatar}`} alt={p.Title} />
                                                    }
                                                </div>
                                                : null}
                                            <div className="product-details flex-col" onClick={() => props.checkCombinationDealsAvailable(p.Id)}>
                                                <div className="product-item-labels">
                                                    {
                                                        props.products[props.products.length - 1].Id === p.Id || props.products[props.products.length - 2].Id === p.Id ?
                                                            <span>{t("new")}</span>
                                                            : null
                                                    }
                                                    {props.hasSinglePromotion(p.Id).Bool ? <span>{t("product-category.promotion")}</span> : null}
                                                </div>
                                                <p className="panel-product-title">{p.Title_translated}</p>
                                                <p className="panel-product-weight" style={{ margin: 0 }}>{props.getAmountOrWeight(p.Id)}</p>
                                            </div>
                                            <div className="product-price-info">
                                                <div onClick={() => props.getInfoProduct(p.Id)} className="info-button">i</div>
                                                {
                                                    props.hasSinglePromotion(p.Id).Bool ?
                                                        <div className="flex-align-center flex-col">
                                                            <div className="product-price-nodiscount">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</div>
                                                            <div className="product-price-discount">€{props.hasSinglePromotion(p.Id).DiscountedPrice.toFixed(2).replace(".", ",")}</div>
                                                        </div>
                                                        :
                                                        <div className="product-price">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    null}
            {
                props.soldOutProducts.length ?
                    <div id="soldOutProducts">
                        <div className="product-flex-container">
                            {
                                props.soldOutProducts.map(p => {
                                    return (
                                        <div key={p.Id} className="product product-flex-item flex-col">
                                            {CustomerConfig.Customer ?
                                                <div className="image-container">
                                                    <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/products/pid${p.Id}_${props.soldOutProducts.find(prod => prod.Id === p.Id).Avatar}`} alt={p.Title} />
                                                </div>
                                                : null}
                                            <div className="product-details flex-col" onClick={() => props.checkCombinationDealsAvailable(p.Id)}>
                                                <div className="product-item-labels">
                                                    <span className="sold-out">Verkocht</span>
                                                    {props.hasSinglePromotion(p.Id).Bool ? <span>{t("product-category.promotion")}</span> : null}
                                                </div>
                                                <p className="panel-product-title">{p.Title}</p>
                                                <p className="panel-product-weight" style={{ margin: 0 }}>{props.getAmountOrWeight(p.Id)}</p>
                                            </div>
                                            <div className="product-price-info">
                                                {props.hasSinglePromotion(p.Id).Bool ?
                                                    <div className="flex-align-center flex-col">
                                                        <div className="product-price-nodiscount">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</div>
                                                        <div className="product-price-discount">€{props.hasSinglePromotion(p.Id).DiscountedPrice.toFixed(2).replace(".", ",")}</div>
                                                    </div>
                                                    :
                                                    <div className="product-price">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    :
                    null}
            {CustomerConfig.Customer ?
                <div className="footer" onClick={() => { props.backToMain(); props.resetTimer(); }}>
                    <img className="mr1" src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-backarrow-icon.png`} width="50" alt="back" /> {t("product-category.back")}
                </div>
                : null}
        </div>
    )
}

export default ProductCategory
