import React, { useEffect, useState, useRef } from 'react'
import FeaturedProducts from '../components/FeaturedProducts';
import ProductCategory from './ProductCategory';
import Button from '@material-ui/core/Button';
import InfoModal from '../components/InfoModal';
import Cart from '../components/Cart';
import ChallengeSpendingPromotion from '../components/ChallengeSpendingPromotion';
import CancelConfirmModal from '../components/CancelConfirmModal';
import CustomSnackbar from '../components/CustomSnackbar';
import CombiDealModal from '../components/CombiDealModal';
import BackArrow from '../images/back-arrow.png'
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import apisettings from '../apiSettings';
import 'whatwg-fetch';
import PromotionsCategory from '../components/PromotionsCategory';
import { checkIfCombiDealProductsAreAvailable, checkIfSingleItemCanBeAdded, updateCloudLockers } from '../controllers/ShoppingScreenController';
import ShoppingErrorScreen from './ShoppingErrorScreen';
import awsconfig from '../awsSettings';
import QRCode from "react-qr-code";
import { BarcodeStartScanCamera } from '../utilities/kimono-x';
import { Feature, useFeatures } from 'flagged';
import { useConfig } from '../providers/ConfigProvider';
import ScannerInstructionsModal from '../components/ScannerInstructionsModal';
import log from 'loglevel';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { useTranslation } from 'react-i18next';
import i18n from '../utilities/i18n';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        background: "#f5f5f5",
        flex: 1,
        minHeight: "100%",
        position: "relative",

        '& .header, .footer': {
            display: "flex",
            fontSize: "34px",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: 700
        },
        '& .header': {
            margin: "20px 0 10px 10px",
        },
        '& #productsScreenLeft': {
            flex: 1,
            padding: "10px",
            display: "flex",
            flexDirection: "column"
        },
        '& #productsScreenRight': {
            background: "#ffffff",

            padding: "10px 8px",
            //paddingTop: "314px",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            boxShadow: "0 0 1px 0 rgba(0,0,0,0.3), 0 4px 8px -2px rgba(0,0,0,0.25)"
        },
        '& .top-right-actions': {
            // height: "303px",
            padding: "2px"
        },
        '& .qr-code-scan': {
            border: "1px solid #CCCCCC",
            borderRadius: "4px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "203.5px",
        },
        '& .qr-code-scan-text': {
            padding: '0px 10px 0px 0px',
        },
        '& .qr-code-scan-image': {
            border: "3px dashed #95C11F",
            borderRadius: "4px",
            padding: "10px",
        },
        '& .qr-code-email': {
            border: "1px solid #CCCCCC",
            borderRadius: "4px",
            padding: "10px 10px 30px 10px",
            marginTop: "10px",
        },
        '& .qr-code-email-text': {
            marginTop: "10px",
        },
        '& .qr-code-email-input': {
            marginTop: "10px",
        },
        '& .qr-code-email-input .MuiInputBase-input': {
            border: "3px dashed #95C11F",
            borderRadius: "4px",
            color: "#000000",
            fontSize: "20px",
        },
        '& .qr-code-email-input .Mui-focused': {
            border: "0px dashed #95C11F",
            borderRadius: "4px",
            color: "#000000",
            fontSize: "20px",
        },
        '& .cart-outer': {
            marginTop: "25px",
        },
        '& #coverImg': {
            display: "block",
            margin: "0 auto"
        },
        '& #printCheckbox': {
            marginBottom: "10px",
        },
        '& #printCheckbox .MuiFormControlLabel-label': {
            fontSize: "16px",
        },
        '& .MuiCheckbox-root svg': {
            width: "48px",
            height: "48px",
        },
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: "#00973B"
        },
        '& .MuiButtonBase-root.secondary-btn ': {
            width: "100%",
            padding: "22px",
            marginTop: "20px",
            textTransform: "none",
            background: "#EBEBEB",
            color: "#222",
            fontSize: "20px"
        },
        '& .MuiButtonBase-root.pay-btn ': {
            width: "100%",
            padding: "22px",
            marginTop: "20px",
            textTransform: "none",
            background: "#00973B",
            color: "#fff",
            fontSize: "20px"
        },
        '& .MuiButtonBase-root.feature-btn ': {
            width: "100%",
            padding: "22px",
            marginTop: "10px",
            textTransform: "none",
            background: "#00973B",
            color: "#fff",
            fontSize: "20px"
        },
        '& .MuiButtonBase-root.feature2-btn ': {
            width: "100%",
            padding: "22px",
            marginTop: "15px",
            textTransform: "none",
            background: "#00973B",
            color: "#fff",
            fontSize: "20px"
        },
        '& .checkout-actions': {
            marginTop: "30px"
        },
        '& .product-flex-container': {
            display: "flex",
            flexWrap: "wrap",
            padding: "0 10px 0 5px"
        },
        '& .product-flex-item': {
            flex: "0 0 31%",
            background: "#fff",
            marginBottom: "20px",
            alignItems: "center",
            marginRight: "2%",
            paddingTop: "20px"
        },
        '& .product-flex-item .image-container': {
            height: "160px",
            display: "flex",
            alignItems: "center",
            position: "relative"
        },
        '& .product-flex-item img': {
            width: "160px"
        },
        '& .product-details': {
            width: "100%",
            padding: "0 30px",
        },
        '& .panel-product-title': {
            fontWeight: 700,
            marginBottom: 5,
            fontSize: "20px",
        },
        '& .panel-product-weight': {
            fontSize: "16px",
            fontWeight: 700,
            color: "#aaa"
        },
        '& .product-price-info': {
            width: "100%",
            padding: "0 25px 25px 25px",
            marginTop: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        '& .info-button': {
            padding: "5px 20px",
            fontSize: "32px",
            background: "#00973B",
            borderRadius: "50%",
            color: "white",
            fontWeight: 700,
        },
        '& .product-price': {
            fontSize: "24px",
            fontWeight: 700
        },
        '& #combinationModal': {
            // minWidth: "450px"
        },
        '& .product-price-nodiscount': {
            fontSize: "20px",
            color: "#CCCCCC",
            textDecoration: "line-through",
            fontWeight: 700,
            textAlign: "right",
            width: "100%"
        },
        '& .product-price-discount': {
            fontSize: "24px",
            color: "#BE1622",
            marginLeft: "30px",
            fontWeight: 700
        },
        '& .product-item-labels': {
            height: "20px",
            bottom: 5,
            left: 5
        },
        '& .product-item-labels>*': {
            background: "#FFF0B3",
            fontWeight: 700,
            fontSize: "14px",
            textTransform: "uppercase",
            padding: "2px",
            marginRight: "5px",
        },
        '& .discountTotal, & .priceSubTotal': {
            borderTop: "1px solid #ccc",
            padding: "10px"
        },
        '& .priceTotal': {
            borderTop: "1px solid #ccc",
            padding: "10px"
        },
        '& .discountTotal>*': {
            color: "#BE1622",
            fontSize: "16px",
        }
    },
}));

function Products() {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();

    const { KioskConfig, CustomerConfig } = useConfig();

    const features = useFeatures();

    const [Blocks, setBlocks] = useState([]);
    const [Jofemars, setJofemars] = useState([]);
    const [AvailableLockers, setAvailableLockers] = useState([]);
    const [Offers, setOffers] = useState([]);
    const [VisibleOffers, setVisibleOffers] = useState([]);
    const [BarcodeScanPromotionFeatureOffers, setBarcodeScanPromotionFeatureOffers] = useState([]);
    const [BarcodeScanChallengeSpendingPromotionFeatureOffers, setBarcodeScanChallengeSpendingPromotionFeatureOffers] = useState([]);
    const [ShowChallengePromotion, setShowChallengePromotion] = useState(false);
    const [ProductCategories, setProductCategories] = useState([]);
    const [Products, setProducts] = useState([]);
    const [SelectedCategoryId, setSelectedCategoryId] = useState(0);
    const [ShowCategory, setShowCategory] = useState(false);
    const [ShowCombinationModal, setShowCombinationModal] = useState(false);
    const [ShowInfoModal, setShowInfoModal] = useState(false);
    const [ShowEmptyCartModal, setShowEmptyCartModal] = useState(false);
    const [ShowStartNewModal, setShowStartNewModal] = useState(false);
    const [CombiDeals, setCombiDeals] = useState([]);
    const [InfoProduct, setInfoProduct] = useState([]);
    const [CartContents, setCartContents] = useState([]);
    const [PaymentInProgress, setPaymentInProgress] = useState(false);
    const ActiveProductId = useRef(0);
    const [DiscountTotal, setDiscountTotal] = useState(0);
    const [PriceSubTotal, setPriceSubTotal] = useState(0);
    const [PriceTotal, setPriceTotal] = useState(0);
    const [ProductsWithAmounts, setProductsWithAmounts] = useState([]);
    const [ShowToastMessage, setShowToastMessage] = useState(false);
    const [ToastMsg, setToastMsg] = useState("");
    const [ShowPromotionsCategory, setShowPromotionsCategory] = useState(false);
    const [SoldOutProducts, setSoldOutProducts] = useState([]);
    const [UnfilteredSoldoutProducts, setUnfilteredSoldoutProducts] = useState([]);
    const [OriginalProducts, setOriginalProducts] = useState([]);
    const [OfferIdsUsed, setOfferIdsUsed] = useState([]);
    const [ShowErrorModal, setShowErrorModal] = useState(false);
    const [BarcodeScanPromotionEmail, setBarcodeScanPromotionEmail] = useState("");
    const [BarcodeScanValueDummy, setBarcodeScanValueDummy] = useState("");
    const [keyboardVisibility, setKeyboardVisibility] = useState(false);
    const [ScannerActive, setScannerActive] = useState(false);
    const [ShowScannerInstructionsModal, setShowScannerInstructionsModal] = useState(false);

    const [timer, setTimer] = React.useState(300);
    const id = React.useRef(null);
    const clear = () => {
        window.clearInterval(id.current);
        window.removeEventListener("click", clickHandler, true);
    }

    function clickHandler(e) {
        if (!(["emailinput", "emailbutton"].includes(e.target.id)
            || e.target.parentNode.id === "emailbutton"
            || e.target.classList.contains("hg-button")
            || e.target.classList.contains("hg-row")
            || e.target.classList.contains("hg-rows"))
        ) {
            setKeyboardVisibility(false);
        }
    }

    useEffect(() => {

        window.addEventListener("click", clickHandler);

        let lang = localStorage.getItem("i18nextLng");
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);

        log.debug('Init shoppingscreen');
        if (document.location.href.indexOf("index") > -1) {
            document.location.href = "/#/shopping-screen";
        } else {
            getBlocksOfStore();
        }

        id.current = window.setInterval(() => {
            setTimer((time) => time - 1)
        }, 1000)
        return () => clear();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (SelectedCategoryId) {
            setShowCategory(true);
        } else {
            setShowCategory(false);
        }
    }, [SelectedCategoryId])

    useEffect(() => {
        if (CombiDeals.length) {
            setShowCombinationModal(true)
        }
    }, [CombiDeals])

    useEffect(() => {
        if (Blocks.length) {
            const jofemars = JSON.parse(localStorage.getItem("Jofemars"));
            // only use Jofemar if it is present in the shop config (localstorage)
            if (jofemars) {
                setJofemars(jofemars);
            }
            getProducts();
        }
        // eslint-disable-next-line
    }, [Blocks])

    React.useEffect(() => {
        if (timer === 0) {
            clear()
            history.push("/")
        }
        // eslint-disable-next-line
    }, [timer])

    useEffect(() => {
        if (InfoProduct.length) {
            setShowInfoModal(true);
            setShowCombinationModal(false);
        } else {
            setShowInfoModal(false);
            setShowCombinationModal(false);
        }
    }, [InfoProduct])

    useEffect(() => {
        if (OriginalProducts.length) {
            const zeroAmountProducts = OriginalProducts.map(item => {
                var temp = Object.assign({}, item);
                temp.Amount = 0;
                return temp;
            });

            const { _products, lockersSorted, soldOutProducts } = getProductAmounts(zeroAmountProducts);

            Object.freeze(OriginalProducts)

            const filteredProducts = _products.filter(p => p.Amount);
            const activeProducts = []
            filteredProducts.forEach(product => {
                if (product) {

                    const attr = JSON.parse(product.Attributes.replace(/'/g, '"'));
                    if (attr.Enabled) {
                        activeProducts.push(product);
                    }
                }
            });

            setUnfilteredSoldoutProducts(soldOutProducts)
            setAvailableLockers(lockersSorted);
            setProducts(activeProducts);
            getProductCategories(activeProducts);
        }
        // eslint-disable-next-line
    }, [OriginalProducts])

    useEffect(() => {
        const activeProducts = JSON.parse(JSON.stringify(Products));
        if (UnfilteredSoldoutProducts.length) {
            const soldOutProducts = JSON.parse(JSON.stringify(SoldOutProducts));
            const filteredSoldOutProducts = soldOutProducts.filter(f => !activeProducts.find(p => p.Id === f.Id));
            setSoldOutProducts(filteredSoldOutProducts);
        }
        if (Products.length) {
            getOffers(activeProducts);
        }
        // eslint-disable-next-line
    }, [Products, UnfilteredSoldoutProducts])

    async function getBlocksOfStore() {
        window.fetch(apisettings.BASE_URL + "/GetBlocksAndLockers")
            .then(response => response.json())
            .then(data => {

                const jofemars = []
                // search all jofemars
                data.forEach(block => {
                    if (block.Code.indexOf("Vision") > -1) {
                        jofemars.push(block);
                    }
                });
                localStorage.setItem("Jofemars", JSON.stringify(jofemars));

                const blocks = data;
                // sort blocks by rack
                blocks.forEach(block => {
                    // lockers: "B2-19-16-Rack 15"
                    // jofemar: "Vision v2-19-11-Vending 1"
                    const arr = block.Code.split(' ');
                    block.Order = parseInt(arr[arr.length - 1], 10);
                    if (block.Code.indexOf("Vision") > -1) {
                        block.Order += 1000;
                    }
                });
                blocks.sort((a, b) => (a.Order > b.Order) ? 1 : -1);

                assignLockersPerBlock(blocks);
            })
            .catch(error => {
                console.log(error);
                setShowErrorModal(true);
            });
    }

    async function assignLockersPerBlock(blocks) {
        const _blocks = blocks;
        localStorage.setItem('StoreLayout', JSON.stringify(_blocks));
        setBlocks(_blocks);
    }

    function getProducts() {
        window.fetch(apisettings.BASE_URL + "/GetProducts?lang=" + i18n.language)
            .then(response => response.json())
            .then(data => {
                setOriginalProducts(data);
            })
            .catch(error => {
                console.log(error);
                setShowErrorModal(true);
            })
    }

    function isPeriodActive(startDate, endDate) {
        let now = new Date();
        now.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        if (startDate <= now && endDate > now) {
            return true;
        } else {
            return false;
        }
    }

    function getOffers(activeProducts) {

        window.fetch(apisettings.BASE_URL + "/GetPromotions?lang=" + i18n.language)
            .then(response => response.json())
            .then(data => {
                // check if offers products are available
                const filteredOffers = [];
                data.forEach(offer => {
                    let offerValid = true;

                    if (isPeriodActive(new Date(offer.StartDate), new Date(offer.EndDate))) {
                        // check if the offer's products are active
                        if (offer.ProductsInOffer) {
                            offer.ProductsInOffer.forEach(productInOffer => {
                                if (!activeProducts.find(p => p.Id === productInOffer.ProductId)) {
                                    offerValid = false;
                                }
                            });
                        }

                        if (offerValid) {
                            filteredOffers.push(offer);
                        }
                    }
                });

                console.log(filteredOffers);

                // divide and set all Offers over the specific types of Offers used in the Application
                setOffers(filteredOffers);
                splitOffersByFeature(filteredOffers);

                getProductCategories(activeProducts)
            })
            .catch(error => {
                console.log(error);
                setShowErrorModal(true);
            })
    }

    function splitOffersByFeature(offers) {
        let visibleOffers = offers;

        if (features.barcodescanpromotion) {
            let barcodescanpromotionFeatureOffers = offers.filter(o => o.IsBarcodeScanPromotion === true);
            visibleOffers = visibleOffers.filter(o => o.IsBarcodeScanPromotion === false);

            console.log('BarcodeScanPromotion Offers: ', barcodescanpromotionFeatureOffers);
            setBarcodeScanPromotionFeatureOffers(barcodescanpromotionFeatureOffers);
        }

        visibleOffers = visibleOffers.filter(o => o.IsChallengeSpendingPromotion === false);

        if (features.challengespendingpromotion) {
            let challengespendingFeatureOffers = offers.filter(o => o.IsChallengeSpendingPromotion === true);
            console.log('challengespendingpromotion:', challengespendingFeatureOffers);
            challengespendingFeatureOffers.forEach((offer) => {
                offer.applied = false;
                offer.noThanks = false;   // noThanks is used to hide the offer from the cart
                offer.visible = false;
                offer.appliedDiscount = 0;
                offer.isScanned = false;  // set to true when the Barcode code is scanned
            })
            ApplyChallengeSpendingPromotionFeatureOffers(0, challengespendingFeatureOffers);
        }
        console.log('Visible Offers: ', visibleOffers);
        setVisibleOffers(visibleOffers);

        console.log('All Offers: ', offers);
    }

    function getProductCategories(activeProducts) {
        window.fetch(apisettings.BASE_URL + "/GetCategories?lang=" + i18n.language)
            .then(response => response.json())
            .then(data => {
                const filteredCategoriesByActiveProducts = [];
                data.forEach(category => {
                    if (activeProducts.find(p => p.ProductCategoryId === category.Id)) {
                        filteredCategoriesByActiveProducts.push(category);
                    }
                });
                setProductCategories(filteredCategoriesByActiveProducts);
            })
            .catch(error => {
                console.log(error);
                setShowErrorModal(true);
            })
    }

    function getProductAmounts(products) {
        const storeLayout = JSON.parse(localStorage.getItem("StoreLayout")) || [...Blocks];
        localStorage.removeItem("StoreLayout")
        const _products = [...products];
        const availableLockers = [];
        const soldOutProducts = [];

        for (let i = 0; i < storeLayout.length; i++) {
            const block = storeLayout[i];
            for (let j = 0; j < block.Lockers.length; j++) {
                const locker = block.Lockers[j];
                if (
                    !locker.IsSoldOut &&
                    !locker.Opened &&
                    locker.Enabled &&
                    locker.ProductId
                ) {
                    availableLockers.push(locker);
                }

                if (locker.IsSoldOut) {
                    soldOutProducts.push(products.find(p => p.Id === locker.ProductId));
                }
            }
        }

        const jofemars = JSON.parse(localStorage.getItem("Jofemars"));
        // only use Jofemar if it is present in the shop config (localstorage)
        if (jofemars) {
            jofemars.forEach(jofemar => {
                let attributesSplit;
                let JofemarContents = [];
                attributesSplit = jofemar.Attributes.split("|");
                attributesSplit.shift();

                attributesSplit.forEach(entry => {
                    JofemarContents.push(JSON.parse(entry.replace(/'/g, '"')));
                });

                for (let i = 0; i < JofemarContents.length; i++) {
                    const row = JofemarContents[i];
                    for (let j = 0; j < row.Columns.length; j++) {
                        const col = row.Columns[j];
                        if (col.ProductId && col.LatestRefill && col.CurrentStock && col.Enabled) {
                            const availableLocker = {
                                ProductId: col.ProductId,
                                ReplenishedAt: col.LatestRefill,
                                CurrentStock: col.CurrentStock,
                                IsJofemar: true,
                                VendingMachineName: row.vendingMachineLogicalName,
                                Code: col.Code
                            };
                            availableLockers.push(availableLocker);
                        }
                    }
                }
            });
        }

        availableLockers.forEach(locker => {
            const product = _products.find(p => p.Id === locker.ProductId);
            const productIndex = _products.findIndex(p => p.Id === locker.ProductId);
            if (locker.IsJofemar) {
                if (product) {
                    if (!product.Amount) {
                        _products[productIndex].Amount = locker.CurrentStock;
                    }
                    else {
                        _products[productIndex].Amount = _products[productIndex].Amount + locker.CurrentStock;
                    }
                }
            } else {
                if (product) {
                    if (!product.Amount) {
                        _products[productIndex].Amount = 1;
                    }
                    else { _products[productIndex].Amount = _products[productIndex].Amount + 1; }
                }
            }

        });

        let lockersSorted = availableLockers.sort(compare);
        return { _products, lockersSorted, soldOutProducts };
    }

    function getAmountOrWeight(Id) {
        const product = Products.find(p => p.Id === Id) ||
            SoldOutProducts.find(p => p.Id === Id)

        let attr;
        let amount;
        if (product) {
            attr = product.Attributes;
            attr = JSON.parse(attr.replace(/'/g, '"'))

            amount = attr.Priority === 'Amount' ?
                attr.Amount :
                attr.Weight
        } else {

            console.log("problem with " + Id)
        }

        //return `${attr.Priority === 'Amount' ? 'Aantal' : 'Gewicht'} ${amount}`;
        return `${amount} ${attr.Priority === 'Amount' ? t('shopping.item') : ''}`;

    }

    function filterCategory(catId) {
        // Show Promotions
        if (catId === 40) {
            setShowCategory(false);
            setShowPromotionsCategory(true);
        } else {
            setSelectedCategoryId(catId);
        }
    }

    function hasSinglePromotion(productId, isQRCodePromotion = false) {
        let hasSinglePromotion = false;
        let discountedPrice = 0;
        let offerId;
        let offerAvatar;

        // check depending on type of Offers
        if (isQRCodePromotion) {
            BarcodeScanPromotionFeatureOffers.forEach(offer => {
                if (!offer.IsCombination) {
                    if (offer.ProductsInOffer.find(p => p.ProductId === productId)) {
                        hasSinglePromotion = true;
                        discountedPrice = getDiscountPrice(productId, offer.Id);
                        offerId = offer.Id;
                        offerAvatar = offer.Avatar;
                    }
                }
            });
        }
        else {
            VisibleOffers.forEach(offer => {
                if (!offer.IsCombination) {
                    if (offer.ProductsInOffer.find(p => p.ProductId === productId)) {
                        hasSinglePromotion = true;
                        discountedPrice = getDiscountPrice(productId, offer.Id);
                        offerId = offer.Id;
                        offerAvatar = offer.Avatar;
                    }
                }
            });
        }
        return { Bool: hasSinglePromotion, DiscountedPrice: discountedPrice, OfferId: offerId, OfferAvatar: offerAvatar };
    }

    function getDiscountPrice(productId, offerId) {
        let discountedPrice;
        let offer = Offers.find(o => o.Id === offerId);
        let product = Products.find(p => p.Id === productId);
        if (offer.ProductsInOffer[0].ProductFixedPrice) {
            discountedPrice = offer.ProductsInOffer[0].ProductFixedPrice;
        } else {
            discountedPrice = product.DefaultPrice - (product.DefaultPrice * offer.ProductsInOffer[0].ProductDiscount);
        }
        return discountedPrice;
    }

    function createCombiItem(offer) {

        const offerItem = {};
        let discountedPrice;
        const product1 = Products.find(p => p.Id === offer.ProductsInOffer[0].ProductId);

        let product2;
        if (offer.ProductsInOffer.length > 1) {
            product2 = Products.find(p => p.Id === offer.ProductsInOffer[1].ProductId);
        }

        offerItem.Title = offer.Title;
        offerItem.DefaultPrice = product2 ?
            product1.DefaultPrice + product2.DefaultPrice :
            offer.ProductsInOffer[0].OfferSameProductUnits ?
                product1.DefaultPrice * (offer.ProductsInOffer[0].OfferSameProductUnits + 1) : product1.DefaultPrice;


        if (offer.ProductsInOffer[0].ProductFixedPrice) {
            discountedPrice = offer.ProductsInOffer[0].ProductFixedPrice;
        } else {
            // dicount percentage offer

            if (offer.ProductsInOffer[0].OfferSameProductUnits) {
                // n+1 deal
                discountedPrice = offerItem.DefaultPrice - (offerItem.DefaultPrice * offer.ProductsInOffer[0].ProductDiscount);
            } else {
                // combi deal 2 products
                let combinedPrice = product1.DefaultPrice + product2.DefaultPrice;
                discountedPrice = combinedPrice - (combinedPrice * offer.ProductsInOffer[0].ProductDiscount);
            }

        }

        offerItem.OfferId = offer.Id;
        offerItem.Avatar = offer.Avatar;
        offerItem.IsCombination = true;
        offerItem.Product1Id = product1.Id;
        offerItem.Product2Id = product2 ? product2.Id : null;
        offerItem.ProductsInOffer = offer.ProductsInOffer;
        offerItem.Product1Amount = getAmountOrWeight(product1.Id);
        offerItem.Product2Amount = product2 ? getAmountOrWeight(product2.Id) : null;
        offerItem.DiscountedPrice = discountedPrice;
        offerItem.OfferSameProductUnits = offer.ProductsInOffer[0].OfferSameProductUnits + 1;

        console.log(offerItem);

        return offerItem;
    }

    function checkCombinationDealsAvailable(productId) {
        setShowInfoModal(false);
        resetTimer();
        const combiDeals = [];
        ActiveProductId.current = productId;

        // only check on visible offers (other types of offers - activated through features - like QRcode offers are excluded from this check to prevent them from showing up on the kiosk!)
        VisibleOffers.forEach(offer => {
            if (offer.IsCombination) {
                if (offer.ProductsInOffer.find(p => p.ProductId === productId)) {
                    const offerItem = createCombiItem(offer);
                    if (offerItem) {
                        combiDeals.push(offerItem);
                    }
                }
            }
        });
        if (combiDeals.length) {
            setCombiDeals(combiDeals)
        } else {
            console.log(CartContents)

            if (!CartContents.find(p => p.Id === productId)) {
                createSingleCartItem(productId);
            } else {
                handleToastMsg(t('shopping.product-already-added'));
            }
        }
    }

    function getInfoProduct(productId) {
        const product = Products.find(p => p.Id === productId);
        setInfoProduct([product]);
    }

    function createSingleCartItem(productId, isQRCodePromotion = false) {

        const { canBeAdded } = checkIfSingleItemCanBeAdded(Products, ProductsWithAmounts, productId);

        if (canBeAdded) {
            const cartContents = [...CartContents];
            const product = Products.find(p => p.Id === productId);
            const { Bool, DiscountedPrice, OfferId, OfferAvatar } = hasSinglePromotion(productId, isQRCodePromotion);

            product.IsItemCombination = false;
            product.IsSinglePromotion = Bool;
            product.DiscountedPrice = DiscountedPrice;
            product.OfferId = OfferId;
            product.OfferAvatar = OfferAvatar;
            product.ProductId = productId;
            cartContents.push(product);
            setCartContents(cartContents);
        } else {
            setToastMsg(t("shopping.product-already-added-promo"));
            setShowToastMessage(true);
        }

        resetTimer();
    }

    function addCombiCartItem(index, combiDeal = null) {
        const cartContents = [...CartContents];
        const activeCombiDeal = combiDeal ? combiDeal[0] : CombiDeals[index];
        const { areProductsAvailable, productsNotAvailable } = checkIfCombiDealProductsAreAvailable(Products, ProductsWithAmounts, combiDeal ? combiDeal[0] : CombiDeals[index]);

        if (areProductsAvailable) {
            cartContents.push(activeCombiDeal);
            setCartContents(cartContents);
        } else {
            setToastMsg(t('shopping.product-not-available', { products: productsNotAvailable.join() }));
            setShowToastMessage(true);
        }

        setShowCombinationModal(false);
    }

    function updateDiscountPrice(amount) {
        setDiscountTotal(amount)
    }

    function getLockerPositionFromCode(code, lockerId) {
        let rackIndex;
        let lockerCGIndex;

        Blocks.forEach((block, blockIndex) => {
            block.Lockers.forEach((locker, lockerIndex) => {
                if (lockerId === locker.Id) {
                    rackIndex = blockIndex;
                    lockerCGIndex = lockerIndex;
                }
            });
        });

        return { rack: rackIndex, locker: lockerCGIndex }
    }

    function assignLockersToOpen() {
        const lockersSorted = [...AvailableLockers];
        const lockersToOpen = [];
        const lockersToOpenLocalGateway = [];
        const JofemarDispenseList = [];

        setPaymentInProgress(true);

        ProductsWithAmounts.forEach(product => {
            for (let i = 0; i < product.Amount; i++) {
                const lockerToOpen = lockersSorted.find(l => l.ProductId === product.ProductId);
                const lockerToOpenIndex = lockersSorted.findIndex(l => l.ProductId === product.ProductId);

                if (lockerToOpen) {
                    console.log("lockertoOpen: " + lockerToOpen.IsJofemar + " " + lockerToOpen.Code + " " + lockerToOpen.Index + " " + lockerToOpen.VendingMachineName);
                    if (lockerToOpen.IsJofemar && lockerToOpen.CurrentStock) {
                        if (lockerToOpen.CurrentStock) {
                            lockersSorted[lockerToOpenIndex].CurrentStock = lockersSorted[lockerToOpenIndex].CurrentStock - 1;
                            const dispense = {
                                vendingMachineName: lockerToOpen.VendingMachineName,
                                code: lockerToOpen.Code
                            };
                            JofemarDispenseList.push(dispense);

                            if (lockersSorted[lockerToOpenIndex].CurrentStock === 0) {
                                console.log("remove")
                                lockersSorted.splice(lockerToOpenIndex, 1);
                            }
                        }

                    } else {
                        lockersToOpen.push(lockerToOpen);
                        lockersToOpenLocalGateway.push(getLockerPositionFromCode(lockerToOpen.Code, lockerToOpen.Id));
                        lockersSorted.splice(lockerToOpenIndex, 1);
                    }
                }
            }
        });

        const LockersCG = updateCloudLockers(lockersToOpen);

        sendTransactionData(Jofemars, JofemarDispenseList, LockersCG, lockersToOpenLocalGateway);
    }

    function sendTransactionData(Jofemars, JofemarDispenseList, LockersCG, lockersToOpenLocalGateway) {

        // set products bought
        const productsSold = [];
        ProductsWithAmounts.forEach(product => {
            const prod = Products.find(p => p.Id === product.ProductId);
            const VAT = prod ? prod.VAT : 9;
            const Price = prod ? prod.DefaultPrice : 1;

            productsSold.push({
                ProductId: product.ProductId,
                Units: product.Amount,
                VAT: VAT,
                Price: Price
            })
        });

        console.log("productsSold:", productsSold)
        console.log("offer ids", OfferIdsUsed)

        const totalOffers = [];
        OfferIdsUsed.forEach(offerId => {
            if (offerId) {
                const offer = Offers.find(o => o.Id === offerId);
                totalOffers.push({
                    OfferId: offer.Id,
                    OfferTitle: offer.Title_translated,
                    FixedPrice: offer.ProductsInOffer[0].ProductFixedPrice,
                    DiscountPercentage: offer.ProductsInOffer[0].ProductDiscount * 100,
                    AdditionalProducts: offer.ProductsInOffer[0].OfferSameProductUnits,
                    ProductIds: offer.ProductsInOffer.map(p => p.ProductId)
                })
            }
        });
        let allOfferIdsUsed = OfferIdsUsed

        const spendingpromotion = BarcodeScanChallengeSpendingPromotionFeatureOffers.find(o => o.applied);
        if (spendingpromotion) {
            allOfferIdsUsed.push(spendingpromotion.Id);
            totalOffers.push({
                OfferId: spendingpromotion.Id,
                OfferTitle: spendingpromotion.Title_translated,
                FixedPrice: spendingpromotion.FixedDiscount,
                DiscountPercentage: spendingpromotion.Discount * 100,
            })
        };
        console.log(totalOffers);

        const basket = {
            "TransactionData": "Receipt Data",
            "AmountPaid": PriceTotal,
            "TransactionDate": formatDateAndTime(new Date()),
            "ProductsSold": productsSold,
            "OfferIds": allOfferIdsUsed,
            "Offers": totalOffers
        }

        let body = {
            basket: basket,
            LockersLG: lockersToOpenLocalGateway,
            LockersCG: LockersCG,
            Vending: Jofemars,
            Dispense: JofemarDispenseList
        };

        var req = JSON.stringify(body);

        console.log(body);

        localStorage.setItem("SendTransactionReqBody", req);
        if (KioskConfig.PaymentTerminalIp === "dummy") {
            sendTransactionToBackend(req);
        }
        else {
            goToCheckout();
        }
    }

    function sendTransactionToBackend(req) {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: req
        };
        fetch(apisettings.BASE_URL + "/SendTransaction", requestOptions)
            .then((response) => {
                if (response.ok) {
                    setToastMsg(t("shopping.transaction-sent"));
                    setShowToastMessage(true);
                    setTimeout(() => {
                        console.log("Delayed for 1 second.");
                    }, 10000);
                    document.location.href = '/index.html#/newsession';
                }
                else {
                    response.text().then((text) => {
                        setToastMsg(text);
                        log.warn('Received nok when posting transaction: ' + text);
                        setShowToastMessage(true);
                    });

                }
            })
            .catch(error => {
                log.error(error);
                setToastMsg(t('shopping.transaction-sent-error'));
                setShowToastMessage(true);
            });;
    }

    function goToCheckout() {
        let PriceTotalCents = PriceTotal * 100;
        PriceTotalCents = isInt(PriceTotalCents) ? PriceTotalCents : Math.round(PriceTotalCents)
        console.log(PriceTotalCents);

        // IM30 payment method
        // save pricetotalcents to localstorage
        localStorage.setItem("PriceTotalCents", PriceTotalCents);
        // goto payware/terminalinstructions and implement StartPayment there
        document.location.href = '/#/payware/terminalinstruction';
    }

    function isInt(n) {
        return n % 1 === 0;
    }

    function compare(a, b) {
        if (a.ReplenishedAt < b.ReplenishedAt) {
            return -1;
        }
        if (a.ReplenishedAt > b.ReplenishedAt) {
            return 1;
        }
        return 0;
    }

    function updateProductsWithAmounts(productsWithAmounts) {
        setProductsWithAmounts(productsWithAmounts);
    }

    function resetTimer() {
        setTimer(300)
    }

    function formatDateAndTime(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes(),
            seconds = '' + d.getSeconds(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (hours.length < 2)
            hours = '0' + hours;
        if (minutes.length < 2)
            minutes = '0' + minutes;
        if (seconds.length < 2)
            seconds = '0' + seconds;

        return [year, month, day].join('-') + `T${hours}:${minutes}:${seconds}`;
    }

    function handleToastMsg(message) {
        setToastMsg(message)
        setShowToastMessage(true);
    }

    function RemoveItemByIndex(index) {
        const cartContents = [...CartContents];
        cartContents.splice(index, 1);
        setCartContents(cartContents);
        if (!cartContents.length) {
            setDiscountTotal(0);
            updateSubTotalPrice(0);
        }
    }

    function ApplyChallengeSpendingPromotionFeatureOffers(priceSubTotal, offers) {
        let priceTotal = priceSubTotal;

        // display offers above visibilitythreshold (non-barcodeScanPromotions only)   
        offers.forEach(offer => {
            offer.visible = (offer.VisibilitySpendingThreshold <= priceSubTotal);
        })

        offers.forEach(offer => {
            offer.visible = (offer.visible && (!offer.IsBarcodeScanPromotion || (offer.IsBarcodeScanPromotion && offer.isScanned)));
        })

        // start all over. Maybe refactor when we really support free products
        offers.forEach(offer => {
            offer.applied = false;
            offer.appliedDiscount = 0;
        });

        const showChallengeBlock = offers.some(x => x.visible);

        while (showChallengeBlock) {
            // first scanned offer
            const firstscannedoffer = offers.find(x => x.IsBarcodeScanPromotion && x.isScanned && x.MinimumSpending <= priceSubTotal && !x.noThanks);

            if (firstscannedoffer) {
                firstscannedoffer.applied = true;
                break;
            }

            offers = offers.sort((a, b) => b.MinimumSpending - a.MinimumSpending);

            // first free product 
            const firstFreeProduct = offers.find(x => !x.IsBarcodeScanPromotion && x.ProductsInOffer && x.MinimumSpending <= priceSubTotal && !x.noThanks);

            if (firstFreeProduct) {
                firstFreeProduct.applied = true;
                firstFreeProduct.appliedDiscount = 0;
                break;
            }

            // highest discount
            const firstDiscount = offers.find(x => !x.IsBarcodeScanPromotion && !x.ProductsInOffer && x.MinimumSpending <= priceSubTotal && !x.noThanks)

            if (firstDiscount) {
                firstDiscount.applied = true;
                break;
            }
            break;
        }

        offers.forEach(offer => {
            if (offer.applied) {
                offer.appliedDiscount = offer.Discount ? (priceSubTotal / 100) * offer.Discount : offer.FixedDiscount;
                priceTotal = priceSubTotal - offer.appliedDiscount;
            }
            else {
                offer.appliedDiscount = 0;
            }
        })

        setBarcodeScanChallengeSpendingPromotionFeatureOffers(offers);
        setPriceTotal(priceTotal);
        setShowChallengePromotion(showChallengeBlock)
    }

    function updateSubTotalPrice(amount) {
        setPriceSubTotal(amount)
        ApplyChallengeSpendingPromotionFeatureOffers(amount, BarcodeScanChallengeSpendingPromotionFeatureOffers)
    }

    function ToggleNoThanksItemByIndex(index) {
        const offers = [...BarcodeScanChallengeSpendingPromotionFeatureOffers];
        offers[index].noThanks = !offers[index].noThanks;
        if (offers[index].noThanks) {
            offers[index].applied = false;
        }
        ApplyChallengeSpendingPromotionFeatureOffers(PriceSubTotal, offers);
    }

    function updateOfferIds(cartValues) {
        const offerIds = [];
        cartValues.forEach(item => {
            for (let i = 0; i < item.Value; i++) {
                if (item.OfferId) {
                    offerIds.push(item.OfferId);
                }
            }
        });
        setOfferIdsUsed(offerIds);
    }

    function addPromotionToCart(promotion, isQRCodePromotion = false) {
        const cartContents = [...CartContents];

        if (promotion.IsCombination) {
            if (!cartContents.find(i => i.OfferId === promotion.Id)) {
                const combiDeal = [];
                const promotionItem = createCombiItem(promotion);
                combiDeal.push(promotionItem);
                addCombiCartItem(0, combiDeal);
            } else {
                handleToastMsg(t('shopping.product-already-added'));
            }
        } else {
            if (!cartContents.find(i => i.ProductId === promotion.ProductsInOffer[0].ProductId)) {
                createSingleCartItem(promotion.ProductsInOffer[0].ProductId, isQRCodePromotion)
            } else {
                handleToastMsg(t('shopping.product-already-added'));
            }
        }
    }

    // FEATURE : BarcodeScanner 
    function handleBarcodeScanner() {
        setScannerActive(true);
        setShowScannerInstructionsModal(true);
        // cameraId (0: Bottom camera, 1: Front camera)
        BarcodeStartScanCamera("http://" + KioskConfig.PaymentTerminalIp + ":9082/api/kimono/", 1, 30)
            .then(response => {

                // response success : true
                if (typeof response.success != 'undefined' && response.success) {

                    // check if usable barcode
                    if (typeof response.barcode != 'undefined' && response.barcode.includes('_') && response.barcode.length > 2) {
                        var barcode = response.barcode;
                        handleScannedBarcode(barcode);
                    }
                    // barcode undefined, does not contain '_' or unaccepted length
                    else {
                        handleToastMsg(t('shopping.promotions-not-found'));
                    }
                }
                // response success : false
                else {
                    // handle errors
                    handleToastMsg(t('shopping.promotions-not-found'));
                    //console.log(FormatDate(new Date(), "HH:mm:ss.fff") + 'KimonoX error, unhandled: ', response.errorText);
                }
            })
            .always(() => {
                setScannerActive(false);
                setShowScannerInstructionsModal(false);
            });
    }

    function handleScannedBarcode(barcode) {
        const barcodeArray = barcode.split('_');

        // handle different kinds of barcodes...
        if (!isNaN(barcodeArray[1])) {
            switch (barcodeArray[0]) {

                // handle promotion
                case "promotion":
                    // check if promotion exists in BarcodeScanPromotionFeatureOffers
                    const promotions = [...BarcodeScanPromotionFeatureOffers];
                    var foundPromotion = promotions.find(i => i.Id === parseInt(barcodeArray[1]));

                    if (foundPromotion) {
                        addPromotionToCart(foundPromotion, true);
                    }
                    else {
                        handleToastMsg(t('shopping.promotions-not-found'));
                    }
                    break;
                case "challengespendingpromotion":
                    {
                        const promotions = [...BarcodeScanChallengeSpendingPromotionFeatureOffers];

                        // check if usage exceeds max usage
                        if (promotions.findIndex(i => i.Id === parseInt(barcodeArray[1]) && i.NoOfUsages >= i.MaximumNoOfUsages) !== -1) {
                            handleToastMsg(t('shopping.promotion-max-used'));
                            break;
                        }

                        var foundCPromotion = promotions.findIndex(i => i.Id === parseInt(barcodeArray[1]));

                        if (foundCPromotion !== -1) {
                            // update promotion and apply (set as scanned)!
                            promotions[foundCPromotion].isScanned = true;
                            ApplyChallengeSpendingPromotionFeatureOffers(PriceSubTotal, promotions);
                        }
                        else {
                            handleToastMsg(t('shopping.promotions-not-found'));
                        }
                        break;
                    }
                default:
                    {   // unaccepted kind of barcode
                        handleToastMsg(t('shopping.barcode-invalid'));
                    }
            }
        }
    }

    function postEmail(email) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ MailAddress: email })
        };
        fetch(apisettings.BASE_URL + "/AddCustomer", requestOptions)
            .then((response) => {
                if (response.ok) {
                    setToastMsg(t('shopping.email-sent'));
                    setBarcodeScanPromotionEmail("");
                    setKeyboardVisibility(false);
                    setShowToastMessage(true);
                }
                else {
                    response.text().then((text) => {
                        setToastMsg(text);
                        log.warn('Received nok when posting /AddCustomer: ' + text);
                        setShowToastMessage(true);
                    });

                }
            })
            .catch(error => {
                log.error(error);
                setToastMsg(t('unknown-error'));
                setShowToastMessage(true);
            });;
    }

    const handleBarcodeScanPromotionEmailChange = (e) => {
        const input = e.target.value;
        setBarcodeScanPromotionEmail(input);
        //keyboard.current.setInput(input);
    };

    const handleBarcodeScanValueDummyChange = (e) => {
        const input = e.target.value;
        setBarcodeScanValueDummy(input);
        //keyboard.current.setInput(input);
    };

    const handleBarcodeScanPromotionEmailKeyboardChange = (input) => {
        console.log(input);
        setBarcodeScanPromotionEmail(input);
    };

    const handleBarcodeScanPromotionEmailKeyPress = (key) => {
        if (key === '{enter}')
            postEmail(BarcodeScanPromotionEmail);
        if (key === '{cancel}') {
            setKeyboardVisibility(false);
            setBarcodeScanPromotionEmail("");
        }
    };

    return (
        <div id="productsScreen" className={classes.root}>
            {/* {timer} */}
            <div id="productsScreenLeft">
                {/* {!ShowCategory && !ShowPromotionsCategory ? */}
                <div id="coverImg">
                    {CustomerConfig.Customer ?
                        <img id="coverImg" src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-shoppingscreen-top.png`} alt="" draggable={false} />
                        : null}
                </div>
                {/* : null} */}

                {!ShowCategory && !ShowPromotionsCategory && ProductCategories.length ? <div className="header" onClick={() => {
                    setShowStartNewModal(true);
                }}>
                    <img className="mr1" src={BackArrow} width="50" alt="back" /> {t('shopping.categories')}
                </div> : null}

                {/* Products */}
                {
                    ShowCategory && !ShowPromotionsCategory ?
                        <ProductCategory
                            productCategories={ProductCategories}
                            soldOutProducts={SoldOutProducts}
                            catId={SelectedCategoryId}
                            products={Products}
                            offers={VisibleOffers}
                            backToMain={() => {
                                setShowCategory(false);
                                setSelectedCategoryId(0);
                            }}
                            getAmountOrWeight={getAmountOrWeight}
                            hasSinglePromotion={hasSinglePromotion}
                            checkCombinationDealsAvailable={checkCombinationDealsAvailable}
                            getInfoProduct={getInfoProduct}
                            resetTimer={resetTimer}
                        /> : null
                }
                {/* Offers */}
                {
                    ShowPromotionsCategory && !ShowCategory ?
                        <PromotionsCategory
                            offers={VisibleOffers}
                            backToMain={() => setShowPromotionsCategory(false)}
                            addCombiCartItem={addCombiCartItem}
                            createSingleCartItem={createSingleCartItem}
                            createCombiItem={createCombiItem}
                            handleToastMsg={handleToastMsg}
                            resetTimer={resetTimer}
                            products={Products}
                            getAmountOrWeight={getAmountOrWeight}
                            cartContents={CartContents}
                            setCombiDeals={(combiDeal) => setCombiDeals(combiDeal)}
                        /> : null
                }
                {/* Popular products */}
                {
                    Products.length && ProductCategories.length && !ShowCategory && !ShowPromotionsCategory ?
                        <FeaturedProducts
                            hasSinglePromotion={hasSinglePromotion}
                            productCategories={ProductCategories}
                            products={Products}
                            filterCategory={filterCategory}
                            checkCombinationDealsAvailable={checkCombinationDealsAvailable}
                            getInfoProduct={getInfoProduct}
                            resetTimer={resetTimer}
                            offers={VisibleOffers}
                            showPromotions={() => {
                                setShowPromotionsCategory(true);
                                setShowCategory(false);
                            }}
                        /> : null
                }

                {!Products.length && !ProductCategories.length &&
                    <h3 className="text-center">{t('shopping.products-loading')}</h3>
                }

            </div>
            <div id="productsScreenRight">

                <div className="top-right-actions">

                    {/* Feature barcodescanner */}
                    <Feature name="barcodescanpromotion">
                        {KioskConfig.PaymentTerminalIp ?
                            <div className="qr-code-scan">
                                <div className="qr-code-scan-text">
                                    <h2>
                                        <span className="green">{t('shopping.qr-code-received')}</span><br />

                                        {!ScannerActive ?
                                            <span>
                                                <Button type="button" className="feature-btn" variant="contained" color="primary" onClick={() => { handleBarcodeScanner(); resetTimer(); }}>{t('shopping.press-here')}</Button>
                                            </span>
                                            :
                                            <span>
                                                {t('shopping.follow-instructions')}
                                            </span>
                                        }
                                    </h2>
                                </div>
                                <div className="qr-code-scan-image">
                                    <QRCode id="QRCode" value='https://nl.wikipedia.org/wiki/QR-code' size={110} />
                                </div>
                            </div>

                            : null
                        }
                        {KioskConfig.PaymentTerminalIp === "dummy" ?
                            <div className="qr-code-scan">
                                <div><TextField
                                    id="scaninput"
                                    className="UpdatedStockValue"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: '100%' }}
                                    placeholder="scandummy"
                                    onChange={handleBarcodeScanValueDummyChange}
                                /></div>
                                <div>
                                    <Button
                                        id="scanbutton"
                                        type="button"
                                        className="feature2-btn"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => { handleScannedBarcode(BarcodeScanValueDummy) }}>{t('scan')}
                                    </Button>
                                </div></div>
                            : null
                        }
                    </Feature>
                    <Feature name="barcodescanpromotionemail">
                        <div className="qr-code-email">
                            <div className="qr-code-email-text"><h2 className="h2-no-margin"><span className="green">{t('shopping.promotion-received')}</span><br /></h2></div>
                            <div className="qr-code-email-input">
                                <TextField
                                    id="emailinput"
                                    className="UpdatedStockValue"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: '100%' }}
                                    placeholder={t('shopping.set-email-watermark')}
                                    value={BarcodeScanPromotionEmail}
                                    onChange={handleBarcodeScanPromotionEmailChange}
                                    onFocus={() => setKeyboardVisibility(true)}
                                />
                                <Button
                                    id="emailbutton"
                                    type="button"
                                    className="feature2-btn"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => { postEmail(BarcodeScanPromotionEmail); }}>{t('shopping.send-to-email')}
                                </Button>
                            </div>
                        </div>
                    </Feature>
                </div>

                <div className="cart-outer">
                    <h1 className="mb0">{t('shopping-basket')}</h1>
                    <h5 className="gray mt5p">{t('shopping.shopping-basket-fill', { count: CartContents.length })}</h5>

                    {CartContents.length ?
                        <Cart
                            items={CartContents}
                            products={Products}
                            offers={Offers}
                            getAmountOrWeight={getAmountOrWeight}
                            updateDiscountDifference={updateDiscountPrice}
                            updateTotalPrice={updateSubTotalPrice}
                            updateProductsWithAmounts={updateProductsWithAmounts}
                            RemoveItemByIndex={RemoveItemByIndex}
                            updateOfferIds={updateOfferIds}
                        />
                        : null}

                    {DiscountTotal ?
                        <div className="discountTotal flex jcsb">
                            <h2>{t('discount')}</h2>
                            <h2>-€{DiscountTotal.toFixed(2).replace(".", ",")}</h2>
                        </div>
                        : null}

                    <Feature name="challengespendingpromotion">
                        {ShowChallengePromotion && PriceSubTotal ?
                            <div className="priceSubTotal flex jcsb">
                                <h2>{t('sub-total')}</h2>
                                <h2>€{PriceSubTotal.toFixed(2).replace(".", ",")}</h2>
                            </div>
                            : null}

                        {ShowChallengePromotion && PriceSubTotal && BarcodeScanChallengeSpendingPromotionFeatureOffers.length ?
                            <ChallengeSpendingPromotion
                                items={CartContents}
                                products={Products}
                                offers={BarcodeScanChallengeSpendingPromotionFeatureOffers}
                                toggleNoThanksItemByIndex={ToggleNoThanksItemByIndex}
                            />
                            : null}
                    </Feature>

                    {PriceTotal ?
                        <div className="priceTotal flex jcsb">
                            <h2>{t('total')}</h2>
                            <h2>€{PriceTotal.toFixed(2).replace(".", ",")}</h2>
                        </div>
                        : null}

                    {CartContents.length ?
                        <div className="checkout-actions">
                            <Button
                                type="button"
                                className={CartContents.length ? "pay-btn" : "secondary-btn"}
                                variant="contained"
                                color="primary"
                                disabled={PaymentInProgress && !CartContents.length}
                                onClick={() => { assignLockersToOpen(); resetTimer(); }}>{t('checkout')}
                            </Button>
                            <Button
                                type="button"
                                className={CartContents.length ? "pay-btn" : "secondary-btn"}
                                variant="contained"
                                color="primary"
                                disabled={PaymentInProgress && !CartContents.length}
                                onClick={() => { setShowEmptyCartModal(true); resetTimer(); }}>{t('shopping.shopping-basket-clear')}
                            </Button>
                        </div>
                        : ""}
                </div>
            </div>

            {
                ShowCombinationModal ?
                    <CombiDealModal
                        CombiDeals={CombiDeals}
                        CartContents={CartContents}
                        addCombiCartItem={addCombiCartItem}
                        handleToastMsg={handleToastMsg}
                        closeModal={() => { setShowCombinationModal(false); }}
                        ActiveProductId={ActiveProductId.current}
                        createSingleCartItem={createSingleCartItem}
                    /> : null
            }

            {ShowInfoModal ?
                <InfoModal
                    product={InfoProduct}
                    checkCombinationDealsAvailable={checkCombinationDealsAvailable}
                    closeModal={() => { setShowCombinationModal(false); }}
                    hasSinglePromotion={hasSinglePromotion}
                    getAmountOrWeight={getAmountOrWeight}
                    close={() => setShowInfoModal(false)}
                    getInfoProduct={getInfoProduct}
                /> : null}

            {ShowScannerInstructionsModal ?
                <ScannerInstructionsModal />
                :
                null}

            {ShowEmptyCartModal ?
                <CancelConfirmModal
                    message={t('shopping.shopping-basket-clear-confirm')}
                    confirm={() => {
                        setCartContents([]);
                        setShowEmptyCartModal(false);
                        updateSubTotalPrice(0);
                        setDiscountTotal(0);
                    }}
                    cancel={() => setShowEmptyCartModal(false)}
                />
                : null}

            {ShowStartNewModal ?
                <CancelConfirmModal
                    message={t('start-new')}
                    confirm={() => {
                        localStorage.setItem("ShoppingAutoRouting", true);
                        document.location.href = '/index.html#/newsession'
                        setShowStartNewModal(false);
                    }}
                    cancel={() => setShowStartNewModal(false)}
                />
                : null}


            {ShowToastMessage ?
                <CustomSnackbar
                    open={ShowToastMessage}
                    toastMessage={ToastMsg}
                    toastType={"error"}
                    handleClose={() => setShowToastMessage(false)}
                /> : null}

            {ShowErrorModal ? <ShoppingErrorScreen goBack={() => {
                clear()
                history.goBack();
            }} /> : null}

            {keyboardVisibility ?
                <div style={{ zIndex: '1000', position: 'absolute', width: '100vw', height: '300px', backgroundColor: '#ffffff', bottom: '0', border: '1px solid #cccccc', padding: '5px' }}>
                    <div className="simple-keyboard">
                        <Keyboard
                            layoutName={"default"}
                            onChange={handleBarcodeScanPromotionEmailKeyboardChange}
                            onKeyPress={handleBarcodeScanPromotionEmailKeyPress}
                            layout={{
                                default: [
                                    "~ 1 2 3 4 5 6 7 8 9 0 + - _ {bksp}",
                                    "q w e r t y u i o p {enter}",
                                    "a s d f g h j k l {cancel}",
                                    "z x c v b n m . @"
                                ]
                            }}
                            display={{
                                '{bksp}': 'Backspace',
                                '{cancel}': 'Clear',
                                '{enter}': 'OK',
                            }}
                        />
                    </div>
                </div>
                : null}
        </div>
    )
}

export default Products
