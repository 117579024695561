import React from 'react';
import awsconfig from '../awsSettings';
import ModalBase from './ModalBase';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useConfig } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        paddingTop: "20px",
        width: "350px",

        '& .close-icon': {
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer"
        },
        '& .description-info': {
            width: "100%",
            textAlign: "left"
        },
        '& .product-details': {
            padding: 0
        },
        '& .product': {
            height: "auto"
        }
    },
}));

function InfoModal(props) {
    const classes = useStyles();
    const { CustomerConfig } = useConfig();
    const { t } = useTranslation();

    return (
        <ModalBase>
            <div id="infoModal" className={classes.root}>
                <div className="header">
                    <div className="flex products-container">
                        {
                            props.product.map(p => {
                                return (
                                    <div key={p.Id} className="product product-flex-item flex-col" >
                                        {CustomerConfig.Customer ?
                                            <div className="image-container">
                                                <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/products/pid${p.Id}_${props.product.find(prod => prod.Id === p.Id).Avatar}`} alt={p.Title} />
                                            </div>
                                            : null}
                                        <div className="product-details flex-col">
                                            <div className="product-item-labels">
                                                {
                                                    props.product[props.product.length - 1].Id === p.Id || props.product[props.product.length - 2].Id === p.Id ?
                                                        <span>{t("new")}</span>
                                                        : null
                                                }
                                                {props.hasSinglePromotion(p.Id).Bool ? <span>{t("promotion")}</span> : null}
                                            </div>
                                            <div className="flex-align-center jcsb">
                                                <p className="m0 panel-product-title">{p.Title_translated}</p>
                                                <p className="m0 product-price">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</p>
                                            </div>
                                            <p className="panel-product-weight mt1" style={{ margin: 0 }}>{props.getAmountOrWeight(p.Id)}</p>
                                        </div>

                                        <p className="description-info">{p.Description_translated}</p>

                                        <div className="footer">
                                            <Button
                                                type="button"
                                                className="green-btn ml1"
                                                variant="contained"
                                                onClick={() => {
                                                    props.checkCombinationDealsAvailable(p.Id);
                                                    props.closeModal();
                                                }
                                                }
                                            >
                                                {t("info-modal.add-to-basket")}
                                            </Button>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {CustomerConfig.Customer ?
                    <div className="close-icon" onClick={props.close}>
                        <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-close-icon.png`} width="50" alt="close" />
                    </div>
                    : null}
            </div>
        </ModalBase>
    )
}

export default InfoModal
