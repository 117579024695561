import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FullScreen from '../components/FullScreen';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {

        display: "flex",
        flexDirection: "column",
        width: "100%",
        background: "#00973B",
        justifyContent: "center",
        alignItems: "center",

        '& .modal': {
            padding: "48px 24px",
            background: "#fff",
            height: "auto",
            width: "508px",
            borderRadius: "12px",
            boxShadow: "0 0 1px 0 rgba(0,0,0,0.3), 0 4px 8px -2px rgba(0,0,0,0.25)",
            textAlign: "center"
        },

        '& .main-title': {
            fontSize: "35px"
        },
        '& .sub-title': {
            fontSize: "24px",
            marginTop: "5px",
        },
        '& .bottom-text': {
            fontSize: "20px",
            marginTop: "50px",
        },

        '& .MuiButtonBase-root ': {
            width: "100%",
            padding: "32px 66px",
            marginTop: "20px",
            fontSize: "20px",
            textTransform: "none"
        },
        '& .MuiButtonBase-root.secondary-btn ': {
            width: "100%",
            padding: "22px",
            marginTop: "20px",
            fontSize: "16px",
            textTransform: "none",
            background: "#EBEBEB",
            color: "#222"
        }
    },
}));

function PaywareNoReceipt() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <FullScreen>
            <div id="PaywareNoReceipt" className={classes.root}>

                <div className="modal">
                    <h1 className="main-title">{t("payware-no-receipt.title")}</h1>
                    <h3 className="sub-title">{t("payware-no-receipt.sub-title")}</h3>
                    <Button
                        type="button"
                        className="darkgreen-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => document.location.href = 'checkout://action=ok_no_receipt'}>{t("payware-no-receipt.continue")}
                    </Button>
                    <Button
                        type="button"
                        className="secondary-btn"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            localStorage.setItem("ShoppingAutoRouting", true);
                            document.location.href = '/index.html#/newsession'
                        }}>{t("payware-no-receipt.back")}
                    </Button>
                    <h4 className="bottom-text">
                        {t("payware-no-receipt.help-text")}<br />{t('contact.optionandname')}
                    </h4>
                    <h3 className="sub-title">{t('contact.optiondetails')}</h3>
                </div>
            </div>
        </FullScreen>
    )
}

export default PaywareNoReceipt
