import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ModalBase from '../components/ModalBase';
import awsconfig from '../awsSettings';
import { useConfig } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';

function CombiDealModal(props) {
  const { CustomerConfig } = useConfig();

  const [SelectedIndex, setSelectedIndex] = useState(0);
    const [ActiveOfferId, setActiveOfferId] = useState(0);
    const { t } = useTranslation();

  useEffect(() => {
    if (props.CombiDeals.length) {
      console.log(props.CombiDeals);
      setActiveOfferId(props.CombiDeals[0].OfferId)
    }
  }, [props.CombiDeals])

  return (
    <ModalBase>
      <div id="combinationModal">
        <div className="header">
          <h3>{t("combi-modal.want-deal")}</h3>
          <h4 className="gray">{t("combi-modal.profit-from-deal")}</h4>
          <div className="flex products-container">
            {
              props.CombiDeals.map((p, index) => {
                return (
                  <div
                    key={index}
                    className={`product product-flex-item flex-col ${SelectedIndex === index ? 'selected' : ''}`}
                    onClick={() => { setSelectedIndex(index); setActiveOfferId(p.OfferId); }}
                  >
                    {CustomerConfig.Customer ?
                      <div className="image-container">
                        <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${p.OfferId}_${p.Avatar}`} alt={p.Title} />
                      </div>
                      : null}
                    <div className="product-details flex-col">
                      <div className="product-item-labels">
                        <span>{t("combi-modal.combi-deal")}</span>
                      </div>
                      <p className="panel-product-title">{p.Title}</p>
                      <p className="panel-product-weight" style={{ margin: 0 }}>
                        {p.Product1Amount}
                        {p.Product2Amount ? <span>&<br /></span> : " x "}
                        {p.Product2Amount ? p.Product2Amount : p.OfferSameProductUnits}
                      </p>
                    </div>
                    <div className="product-price-info flex-reverse">
                      <div className="flex-align-center">
                        <div className="product-price-nodiscount">€{p.DefaultPrice.toFixed(2).replace(".", ",")}</div>
                        <div className="product-price-discount">€{p.DiscountedPrice.toFixed(2).replace(".", ",")}</div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="footer">
          <Button
            type="button"
            className="green-btn ml1"
            variant="contained"
            onClick={() => {
              if (!props.CartContents.find(i => i.OfferId === ActiveOfferId)) {
                props.addCombiCartItem(SelectedIndex);
              } else {
                props.handleToastMsg(t("combi-modal.product-already-added"));
                props.closeModal();
              }
            }}
          >
            {t("combi-modal.add-to-basket")}
          </Button>
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              if (!props.CartContents.find(i => i.ProductId === props.ActiveProductId)) {
                props.createSingleCartItem(props.ActiveProductId)
              } else {
                props.handleToastMsg(t("combi-modal.product-already-added"));
              }
              props.closeModal();
            }}
          >
            {t("combi-modal.no-thanks")}
          </Button>

        </div>
      </div>
    </ModalBase>
  )
}

export default CombiDealModal
