import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {},
        fallbackLng: "nl",
        fallbackNS: "global",
        defaultNS: "global",
        debug: true
    });

//   i18next
//   .use(initReactI18next)
//   .init({
//     debug: true,
//     lng: 'nl',
//     fallbackLng: false,
//     resources: {
//       "nl": {
//         "translation": {
//           "start.customername": "Appeltje - Eitje",
//           "start.customer-slogan-1": "Dagvers ",
//           "start.customer-slogan-2": "van onze boerderij"
//         }
//       }
//     }
//   });

export default i18n;