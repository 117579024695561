import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import awsconfig from '../awsSettings';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ModalBase from './ModalBase';
import CustomSnackbar from '../components/CustomSnackbar';
import { useConfig } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "1200px",
    overflowY: "auto",

    '& .cart-item-outer': {
      marginTop: "20px",
      borderTop: "1px solid #ccc",
      padding: "10px 0"
    },
    '& .cart-item': {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 10px 0 0"
    },
    '& .cart-item img': {
      width: "60px"
    },
    '& .cart-item-images': {
      display: "flex",
      flexDirection: "column"
    },
    '& .cart-item-details': {
      width: "180px",
      padding: "0 3px"
    },
    '& .cart-item-labels>*': {
      background: "#FFF0B3",
      fontWeight: 700,
      fontSize: "14px",
      textTransform: "uppercase",
      padding: "2px",
      marginRight: "5px",
    },
    '& .item-title': {
      margin: "5px 0",
      fontSize: "14px"
    },
    '& .cart-item-price': {
      fontSize: "20px",
      fontWeight: 700
    },
    '& .cart-amount-handler': {
      marginTop: "20px",
      justifyContent: "center",
    },
    '& .cart-amount-handler .MuiButtonBase-root': {
      minWidth: "70px",
      fontSize: "24px",
      padding: "0!important",
      height: "70px"
    },
    '& .cart-amount-handler .MuiButtonBase-root .MuiButton-label': {
      paddingBottom: "5px",
      fontSize: "57px"
    },
    '& .cart-amount-handler .MuiTextField-root': {
      width: "70px",
      height: "70px",
      margin: "0 10px",
      background: "white",

    },
    '& .cart-amount-handler .MuiTextField-root input': {
      width: "60px",
      height: "53px",
      fontSize: "24px",
      textAlign: "center"
    },
    '& .cart-item-price-nodiscount': {
      fontSize: "20px",
      color: "#CCCCCC",
      textDecoration: "line-through",
      fontWeight: 700,
      textAlign: "right",
      marginTop: "15px"
    },
    '& .cart-item-price-discount': {
      fontSize: "24px",
      color: "#BE1622",
      fontWeight: 700
    },
    '& .remove-icon': {
      marginTop: "35px",
      marginRight: "15px"
    },
    '& .remove-icon img': {
      width: "35px",
      opacity: 0.4
    }
  },
}));

function Cart(props) {
  const classes = useStyles();
  const { CustomerConfig } = useConfig();

  const [CartValues, setCartValues] = useState([]);
  const [ProductsWithAmounts, setProductsWithAmounts] = useState([]);
  const [IndexToRemove, setIndexToRemove] = useState(null);
  const [ShowRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const [ShowToastMessage, setShowToastMessage] = useState(false);
  const [ToastMsg, setToastMsg] = useState("");
  const { t } = useTranslation();

  function handleChange(e, index, action) {
    const cartValues = [...CartValues];

    if (action === "increment") {
      cartValues[index].Value = checkCanIncrement(cartValues[index]) ?
        cartValues[index].Value + 1 : cartValues[index].Value;
    } else {
      if (cartValues[index].Value > 1) {
        cartValues[index].Value = cartValues[index].Value - 1;
      } else {
        setIndexToRemove(index);
        setShowRemoveItemModal(true);
      }
    }

    setCartValues(cartValues);
  }

  function handleToastMsg(message) {
    setToastMsg(message)
    setShowToastMessage(true);
  }

  function checkCanIncrement(cartItem) {
    let canIncrement = true;
    const productsToCheck = [];
    if (cartItem.OfferId) {
      const offer = props.offers.find(o => o.Id === cartItem.OfferId);
      offer.ProductsInOffer.forEach(product => {
        productsToCheck.push(product.ProductId);
      });
    } else {
      productsToCheck.push(cartItem.ProductId);
    }

    if (ProductsWithAmounts.length) {
      ProductsWithAmounts.forEach(product => {
        productsToCheck.forEach(productToCheck => {
          if (productToCheck === product.ProductId) {
            const dbProduct = props.products.find(p => p.Id === product.ProductId);
            if (product.Amount >= dbProduct.Amount) {
              canIncrement = false;
              console.log('Out of ' + dbProduct.Title);
                handleToastMsg(t("cart.no-products"));
            }
          }
        });

      });
    }
    return canIncrement;
  }

  useEffect(() => {
    if (props.items.length) {

      console.log(props.items)

      if (props.items.length > CartValues.length) {
        const items = props.items;
        updateCartValues(items[items.length - 1]);
      }
    } else {
      setCartValues([])
    }
    // eslint-disable-next-line
  }, [props.items])

  useEffect(() => {
    props.updateProductsWithAmounts(ProductsWithAmounts);
    // eslint-disable-next-line
  }, [ProductsWithAmounts])

  function updateCartValues(item) {
    const cartValues = [...CartValues];

    // handle Offers
    if (item.OfferId) {
      if (cartValues.find(p => p.OfferId === item.OfferId)) {
      } else {
        cartValues.push(
          {
            OfferId: item.OfferId,
            Value: 1,
            DefaultPrice: item.DefaultPrice,
            DiscountedPrice: item.DiscountedPrice,
          })
      }
    } else {
      if (cartValues.find(p => p.Id === item.Id)) {
      } else {
        cartValues.push({
          ProductId: item.Id,
          Value: 1,
          DefaultPrice: item.DefaultPrice,
          DiscountedPrice: item.DiscountedPrice,
        })
      }
    }

    setCartValues(cartValues);
  }

  useEffect(() => {
    if (CartValues.length) {
      console.log("CartValues", CartValues);
      // console.log(props.items);
      calculatePrices(CartValues);
      updateProductAmounts(CartValues);
      props.updateOfferIds(CartValues);
    }
    // eslint-disable-next-line
  }, [CartValues])

  function updateProductAmounts(cartValues) {
    const productWithAmounts = [];
    cartValues.forEach(item => {
      if (item.OfferId) {
        const offer = props.offers.find(o => o.Id === item.OfferId);
        for (let i = 0; i < item.Value; i++) {

          offer.ProductsInOffer.forEach(product => {
            if (productWithAmounts.find(p => p.ProductId === product.ProductId)) {
              const indexToUpdate = productWithAmounts.findIndex(p => p.ProductId === product.ProductId);
              productWithAmounts[indexToUpdate].Amount = productWithAmounts[indexToUpdate].Amount + 1;
            } else {
              productWithAmounts.push({
                ProductId: product.ProductId,
                Amount: 1
              })
            }
          });
        }

      } else {
        for (let i = 0; i < item.Value; i++) {
          if (productWithAmounts.find(p => p.ProductId === item.ProductId)) {
            const indexToUpdate = productWithAmounts.findIndex(p => p.ProductId === item.ProductId);
            productWithAmounts[indexToUpdate].Amount = productWithAmounts[indexToUpdate].Amount + 1;
          } else {
            productWithAmounts.push({
              ProductId: item.ProductId,
              Amount: 1
            })
          }
        }
      }
    });

    setProductsWithAmounts(productWithAmounts);
  }

  function calculatePrices(cartValues) {
    let totalPrice = 0;
    let discountPrice = 0;
    cartValues.forEach(item => {
      let itemPrice = item.DiscountedPrice ?
        item.DiscountedPrice * item.Value :
        item.DefaultPrice * item.Value;
      totalPrice = totalPrice + itemPrice;

      let itemDiscountPrice = item.DiscountedPrice ?
        (item.DefaultPrice - item.DiscountedPrice) * item.Value : 0;
      itemDiscountPrice = parseFloat(itemDiscountPrice.toFixed(2))
      discountPrice = discountPrice + itemDiscountPrice;
      discountPrice = parseFloat(discountPrice.toFixed(2))
    });

    totalPrice = parseFloat(totalPrice.toFixed(2))

    props.updateDiscountDifference(discountPrice);
    props.updateTotalPrice(totalPrice);
  }

  return (
    <div id="cart" className={classes.root}>
      {CartValues.length && props.items.length === CartValues.length ?
        CartValues.map((item, index) => {
          return (

            // check here for combi deal
            <div key={index} className="flex-col cart-item-outer">
              <div className="cart-item">
                {CustomerConfig.Customer ?
                  <div className="cart-item-images">
                    {props.items[index].OfferId
                      ? props.items[index].IsCombination
                        ? <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${props.items[index].OfferId}_${props.items[index].Avatar}`} alt={props.items[index].Title} />
                        : <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/promotions/pid${props.items[index].OfferId}_${props.items[index].OfferAvatar}`} alt={props.items[index].Title} />
                      : <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/products/pid${props.items[index].Id}_${props.products.find(prod => prod.Id === props.items[index].Id).Avatar}`} alt={props.items[index].Title} />
                    }
                  </div>
                  : null}
                <div className="cart-item-details flex-col">
                  <div className="cart-item-labels flex">
                    {props.items[index].IsCombination ? <span>{t("cart.combi-deal")}</span> : null}
                    {props.items[index].IsSinglePromotion ? <span>{t("cart.offer")}</span> : null}
                  </div>
                  <div className="item-title">
                    <h3 className="m0">{props.items[index].Title_translated}</h3>
                  </div>
                  <div className="amount">
                    {props.items[index].IsCombination ?
                      <div className="flex-col">
                        <h5 className="gray m0">{props.items[index].Product1Amount} +</h5>
                        <h5 className="gray m0">{props.items[index].Product2Amount}</h5>
                      </div>
                      : <h5 className="gray m0">{props.getAmountOrWeight(props.items[index].Id)}</h5>}
                  </div>
                </div>

                <div className="cart-item-price-outer">
                  {
                    props.items[index].IsSinglePromotion || props.items[index].IsCombination ?
                      <div className="flex-col">
                        <div className="cart-item-price-nodiscount">€{props.items[index].DefaultPrice.toFixed(2).replace(".", ",")}</div>
                        <div className="cart-item-price-discount">€{props.items[index].DiscountedPrice.toFixed(2).replace(".", ",")}</div>
                      </div>
                      :
                      <div className="cart-item-price">€{props.items[index].DefaultPrice.toFixed(2).replace(".", ",")}</div>
                  }
                </div>

              </div>

              <div className="flex jcsb">
                <div className="cart-amount-handler flex">
                  <Button variant="contained" className="gray-btn decrement-stock"
                    onClick={(e) => handleChange(e, index, "decrement")}
                  >-</Button>
                  <TextField
                    className="UpdatedStockValue"
                    type="text"
                    value={CartValues.length && CartValues[index].Value ? CartValues[index].Value : 0}
                    // value={0}
                    variant="outlined"
                    size="small"
                  />
                  <Button variant="contained" className="gray-btn increment-stock"
                    onClick={(e) => handleChange(e, index, "increment")}
                  >+</Button>
                </div>
                {CustomerConfig.Customer ?
                  <div className="remove-icon" onClick={() => { setIndexToRemove(index); setShowRemoveItemModal(true); }}>
                    <img src={`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${CustomerConfig.Customer}/images/kiosk/kiosk-remove-icon.png`} alt="" />
                  </div>
                  : null}
              </div>
            </div>
          )
        }) : null}

      {ShowRemoveItemModal ?
        <ModalBase>
          <div className="flex-col p20">
            <div className="text-center">
              <h2>{t("cart.remove-product")}</h2>
            </div>
            <div className="mt1">

              <Button
                type="button"
                className="confirm-btn"
                variant="contained"
                onClick={() => { setShowRemoveItemModal(false) }}
              >
                              { t("no-upper")}
              </Button>
              <Button
                type="button"
                className="green-btn ml1 confirm-btn"
                variant="contained"
                onClick={() => {
                  const cartValues = [...CartValues];
                  cartValues.splice(IndexToRemove, 1);
                  setCartValues(cartValues);
                  setShowRemoveItemModal(false);
                  props.RemoveItemByIndex(IndexToRemove);
                }}
              >
                              {t("yes-upper")}
              </Button>
            </div>
          </div>
        </ModalBase>
        : null}

      {ShowToastMessage ?
        <CustomSnackbar
          open={ShowToastMessage}
          toastMessage={ToastMsg}
          toastType={"error"}
          handleClose={() => setShowToastMessage(false)}
        /> : null}

    </div>
  )
}

export default Cart
