import React, { useEffect } from 'react'

function NewSession() {
    useEffect(() => {
        let lang = localStorage.getItem("i18nextLng");
        localStorage.clear();
        localStorage.setItem("i18nextLng", lang);
    document.location.href = "/#/";
  }, [])

  return (
    <div>
    </div>
  )
}

export default NewSession
