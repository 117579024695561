import React, { useEffect, useContext } from 'react';
import awsconfig from '../awsSettings';
import i18n from '../utilities/i18n';
import { ConfigContext } from './ConfigProvider';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export const TextAndLanguageProvider = ({ children }) => {
    const { CustomerConfig, KioskConfig, EnabledFeatures } = useContext(ConfigContext);
    const { t } = useTranslation();
    const lngs = {
        en: { name: t("language.en") },
        nl: { name: t("language.nl") }
    }

    async function fetchTranslations(language, customer, filename) {
        fetch(`https://${awsconfig.Storage.AWSS3.bucket}.s3-${awsconfig.Storage.AWSS3.region}.amazonaws.com/public/${customer}/text/kiosk/${filename}-${language}.json`)
            .then(data => data.json())
            .then(data => {
                i18n.addResourceBundle(language, customer, data);
            })
            .catch(error => { console.log(error) });
    }

    useEffect(() => {

        fetchTranslations("nl", "global", "global");
        fetchTranslations("en", "global", "global");

        if (CustomerConfig.Customer) {
            fetchTranslations("nl", CustomerConfig.Customer, "customer");
            fetchTranslations("en", CustomerConfig.Customer, "customer");
        }

    }, [CustomerConfig.Customer])

    return (
        <ConfigContext.Provider value={{ KioskConfig, CustomerConfig, EnabledFeatures }}>
            {Object.keys(lngs).map((lng) => (<Button key={lng} onClick={() => i18n.changeLanguage(lng)}>{lngs[lng].name}</Button>))}
            {children}
        </ConfigContext.Provider>
    )
}

export const useTextAndLanguage = () => useContext(ConfigContext);